<template>
  <main id="controller-view">
    <div class="dashboard-header">
      <p class="dashboard-header__breadcrumb">{{ $t('campaigns-detail') }}</p>
      <!-- <form class="form dashboard-header__form" @submit.prevent>
        <input type="text" class="input__search input--white-bg" :placeholder="$t('search')" v-model="searchText">
        <button v-if="searchText" id="btn-clear-text" class="btn btn--clear-text" @click.prevent="clearSearchText">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('clear')" :title="$t('clear')" class="btn__img btn__img--small">
        </button>
      </form> -->
    </div>
    <div class="tabs__tab-content-actions">
      <button class="btn btn--filter" :class="{ '--filter-active': isColumnsFilterActive }" @click.prevent="columnsFilterShowModal">
        <img src="/img/baseline_view_week_white_48dp.png" :alt="$t('columns')" :title="$t('columns')" class="btn__img btn__img--small">{{ $t('columns') }}
      </button>
      <!-- COLUMNS-SELECTOR-MODAL -->
      <columns-selector
        id="columns-filter-selector"
        v-if="isColumnsSelectorVisible"
        :columnsFilter="columnsFilter"
        :columnsMeta="columnsMeta"
        v-on:on-columns-filter-hide-modal="onColumnsFilterHideModal"
        v-on:on-columns-filter-change="onColumnsFilterChange"></columns-selector>
    </div>
    <div class="card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('plannings') }}</h4>
          </div>
          <div class="card__header-actions">
          </div>
        </div>
        <div class="scrollable-cols">
          <table class="card__table">
            <colgroup>
              <col class="--w50px">
              <col :class="[ showFullWidthName ? '--w600px' : '--w300px' ]">
              <template v-for="(columnId, columnIndex) in columnsFilter">
                <col :class="getColumnsMeta(columnId).class" :key="columnIndex">
              </template>
              <col class="--w100px">
            </colgroup>
            <thead>
              <tr>
                <th class="sticky --left-1">&nbsp;</th>
                <th class="sticky --left-2">
                  {{ $t('name') }}
                  <button class="btn btn--square--small btn--sticky-right" @click="showFullWidthName = !showFullWidthName">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': showFullWidthName }" src="/img/baseline_swap_horiz_white_48dp.png" :alt="$t('expand')">
                  </button>
                </th>
                <template v-for="(columnId, columnIndex) in columnsFilter">
                  <th class="--ta-r" :key="columnIndex">
                    {{ getColumnsMeta(columnId).label }}
                    <button class="btn btn--square--small" @click="changeTableOrder(columnId)" v-if="getColumnsMeta(columnId).sortable">
                      <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === columnId }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                    </button>
                  </th>
                </template>
                <th class="sticky --right">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(user) in orders">
                <template v-for="(line) in user">
                <h1 :key="line._id">{{ line['campaign_ids'] }}</h1>
              </template>
              </template>
              <template v-for="(campaign) in campaigns">
                <tr :key="campaign._id" @dblclick="onTableRowDblClick(campaign._id)">
                  <td class="sticky --left-1">&nbsp;</td>
                  <td  class="sticky --left-2" :title="campaign.name">{{ campaign.name }}</td>
                  <td class="--ta-r">
                    {{ $t(`status-${campaign.status}`) }}
                  </td>
                  <template v-for="(meta, idx) in columnsMeta">
                    <td v-if="idx > 0" class="--ta-r" :key="idx">{{ format(campaign[meta.id], meta.format) }}</td>
                  </template>
                  <td class="--ta-r sticky --right">
                    <div class="table-cell-actions">
                    <router-link :to="{ path: '/details', query: $route.query, params:campaign }" :title="DetailsCampaign">
                        <button class="btn btn--hover" :title="$t('visibility')">
                          <img class="btn__img" src="/img/baseline_visibility_white_48dp.png">
                          {{$t('details')}}
                        </button>
                    </router-link>

                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="campaigns.length === 0" id='noActiveCampaigns'>
                <h1> {{ $t('no-active-campaigns') }}</h1>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card__actions">
        <div class="card__actions--left">&nbsp;</div>
        <div class="card__actions--right">
          <div class="tfoot__rows-page"
            v-for="(page, index) in new Array(pagination.totalPages)"
            :key="index"
            :class="{ 'tfoot__current-page': pagination.currentPage - 1 === index }"
            @click="setCurrentPage(index + 1)">
            {{ 1 + (index * pagination.perPage) }} - {{ Math.min((index + 1) * pagination.perPage, pagination.total) }}</div>
          <div class="tfoot__total-rows">&nbsp;{{ $t('of') }}&nbsp;{{ pagination.total }}&nbsp;</div>
          <div class="tfoot__page-actions">
            <button class="btn btn--square--small" :disabled="pagination.currentPage === 1" @click="setCurrentPage(pagination.currentPage - 1)">
              <img class="btn__img" src="/img/baseline_chevron_left_white_48dp.png" :alt="$t('prev')">
            </button>
            <button class="btn btn--square--small" :disabled="pagination.currentPage === pagination.totalPages" @click="setCurrentPage(pagination.currentPage + 1)">
              <img class="btn__img" src="/img/baseline_chevron_right_white_48dp.png" :alt="$t('next')">
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ColumnsSelector from '@/core/components/ColumnsSelector.vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  SHOW_MODAL,
  HIDE_MODAL,
  GET_ACTIVE_CAMPAIGNS,
  GET_TEAMS,
  GET_ORDERS,
  GET_USER_DATA

} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'details',
  components: {
    ColumnsSelector
  },
  data: function () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalPages: 1,
        total: 0
      },
      campaigns: [],
      orders: [],
      users: [],
      orderField: 'none',
      orderedTableItems: [],
      showFullWidthName: false,
      searchText: '',
      locale: i18n.locale,
      columnsFilter: [],
      isColumnsSelectorVisible: false,
      isColumnsFilterActive: false,
      columnsMeta: [
        { id: 'status', label: this.$t('status'), format: '', sortable: false, class: '--w150px' },
        { id: 'start_date', label: this.$t('start-date'), format: 'date', sortable: false, class: '--w150px' },
        { id: 'end_date', label: this.$t('end-date'), format: 'date', sortable: false, class: '--w150px' },
        { id: 'budget', label: this.$t('budget'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'cpr', label: this.$t('cpr'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'results', label: this.$t('results'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'eCPR', label: this.$t('eCPR'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'spend', label: this.$t('spend'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'margin', label: this.$t('margin'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'bid', label: this.$t('bid'), format: '', sortable: false, class: '--w150px' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'controllerOrders',
      'filteredClients',
      'filteredLineItems',
      'filteredOrders'
    ])
  },
  mounted () {
    this.fecthDataAfterAuthData()
    this.columnsFilter = window.localStorage.getItem('columnsFilter_controller') ? window.localStorage.getItem('columnsFilter_controller').split(',') : this.columnsMeta.map(item => item.id)
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    },
    columnsFilter (newValue, oldValue) {
      window.localStorage.setItem('columnsFilter_controller', newValue.join(','))
      this.isColumnsFilterActive = true
    },
    controllerOrders (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue || [])
    },
    filteredClients (newValue, oldValue) {
      this.$route.query.ftc = [...this.filteredClients]
      this.addParamsToLocation()
    },
    filteredOrders (newValue, oldValue) {
      this.$route.query.fo = [...this.filteredOrders]
      this.addParamsToLocation()
    },
    filteredLineItems (newValue, oldValue) {
      this.$route.query.fli = [...this.filteredLineItems]
      this.addParamsToLocation()
    }
  },
  methods: {
    ...mapActions({
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      getActiveCampaigns: GET_ACTIVE_CAMPAIGNS,
      getOrders: GET_ORDERS,
      fetchTeams: GET_TEAMS,
      getUsers: GET_USER_DATA
    }),
    format (value, format, nullsy) {
      let returnValue
      if (nullsy === true && value === undefined) {
        returnValue = ''
      } else {
        switch (format) {
          case 'number':
            returnValue = this.formatNumber(value)
            break
          case 'currency':
            returnValue = this.formatCurrency(value)
            break
          case 'decimal':
            returnValue = this.formatDecimal(value)
            break
          case 'date':
            returnValue = this.formatDate(value)
            break
          case 'dateLong':
            returnValue = this.formatDateLong(value)
            break
          case 'dateForDateFilter':
            returnValue = this.formatDateForDateFilter(value)
            break
          default:
            returnValue = this.$t(value) !== value ? this.$t(value) : value
        }
      }
      return returnValue
    },
    formatDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    formatDateLong (date) {
      return new Date(date).toLocaleString(this.locale, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
    },
    formatDateForDateFilter (date) {
      return new Date(date).toLocaleString(this.locale, { month: 'short', day: 'numeric' })
    },
    formatCurrency (value) {
      return Number(value || 0).toLocaleString(this.locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatDecimal (value) {
      return Number(value || 0).toLocaleString(this.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatNumber (value) {
      return Number(value || 0).toLocaleString(this.locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    },
    fecthDataAfterAuthData () {
      if (this.authData && this.authData !== null) {
        this.fetchData()
      } else {
        window.setTimeout(this.fecthDataAfterAuthData.bind(this), 100)
      }
    },
    fetchData () {
      const campaignQuery = {
        onComplete: (data) => { this.campaigns = data.data }
      }
      this.getActiveCampaigns(campaignQuery)

      const orderQuery = {
        onComplete: (data) => { this.orders = data.data }
      }
      this.getOrders(orderQuery)

      const userQuery = {
        onComplete: (data) => { this.users = data.data }
      }
      this.getUsers(userQuery)
    },
    addParamsToLocation () {
      const params = this.$route.query
      const queryString = Object.keys(params).map(key => {
        return (typeof params[key] === 'string') ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : params[key].map(keyValue => `${key}=${keyValue}`).join('&')
      }).join('&')
      window.history.pushState({}, null, `${this.$route.path}?${queryString}`)
    },
    columnsFilterShowModal () {
      this.isColumnsSelectorVisible = true
    },
    getColumnsMeta (columnId) {
      return this.columnsMeta.find(column => column.id === columnId)
    },
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'role':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'status':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.status.toUpperCase(), b.status.toUpperCase()))
          break
        case 'start_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'end_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.end_date), new Date(b.end_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTeams = this.orderTableByField(this.orders)
    },
    clearSearchText () {
      this.searchText = ''
    },
    // EVENTS:
    onColumnsFilterHideModal () {
      this.isColumnsSelectorVisible = false
    },
    onColumnsFilterChange ({ columnsFilter }) {
      this.columnsFilter = [...columnsFilter]
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#controller-view {
  padding: 2rem 2rem 100px;
  display: flex;
  flex-direction: column;
}
.card__header + .card--with-header {
  margin-bottom: 20px;
}
.dashboard-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 20px;
}
.dashboard-header__breadcrumb {
  font-size: 24px;
  flex-basis: calc(100% - 650px);
  max-width: calc(100% - 660px);
  display: flex;
}
.dashboard-header__form {
  display: flex;
  position: relative;
}
#btn-clear-text {
  position: absolute;
  left: 240px;
}
</style>
